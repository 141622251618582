import React, { useEffect, useState, useRef } from 'react';
import 'bulma/css/bulma.css';
import './App.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPhone,
  faShieldAlt,
  faExchangeAlt,
  faTruck,
  faHandshake,
  faUser,
  faCar,
  faHome,
} from '@fortawesome/free-solid-svg-icons';

import { faFacebookF, faInstagram } from '@fortawesome/free-brands-svg-icons';

import logo from './logo.png';
import logowhite from './logowhite.png';
import logopink from './logopink.png';
import heroImage from './hero.jpg';
import AOS from 'aos';
import 'aos/dist/aos.css';

function App() {
  const [activeSection, setActiveSection] = useState('Home');
  const [isSidebarVisible, setSidebarVisible] = useState(false);
  const aboutUsRef = useRef(null);
  const ourCarsRef = useRef(null);
  const contactRef = useRef(null);
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [status, setStatus] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [scrolled, setScrolled] = useState(false); // Define scrolled state here
  const [selectedSubject, setSelectedSubject] = useState('General Inquiry');

  useEffect(() => {
    const handleScroll = () => {
      const currentScroll = window.scrollY + window.innerHeight / 2;

      if (currentScroll < ourCarsRef.current.offsetTop)
        setActiveSection('Home');
      else if (currentScroll < aboutUsRef.current.offsetTop)
        setActiveSection('Our Cars');
      else if (currentScroll < contactRef.current.offsetTop)
        setActiveSection('About Us');
      else setActiveSection('Contact');

      setSidebarVisible(window.scrollY > 0);
      setScrolled(window.scrollY > 150);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    const handleLoad = () => {
      setIsLoading(false);
    };

    if (document.readyState === 'complete') {
      // If the document is already loaded completely, hide the loader
      setIsLoading(false);
    } else {
      // If the document is still loading, set up an event listener
      window.addEventListener('load', handleLoad);
    }

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener('load', handleLoad);
    };
  }, []);


  useEffect(() => {
    AOS.init();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('email', email);
    formData.append('message', message);
    formData.append('subject', selectedSubject); // Add selected subject to form data
    try {
      const response = await fetch('https://formspree.io/f/xgejpnln', {
        method: 'POST',
        body: formData,
      });
      if (response.ok) {
        setStatus(
          'Takk for din melding! Vi vil komme tilbake til deg snart.'
        );
        setEmail('');
        setMessage('');
        setSelectedSubject('General Inquiry'); // Reset subject after successful submission
      } else {
        setStatus('Oops! Noe gikk galt med innsendingen av skjemaet.');
      }
    } catch (error) {
      setStatus('Melding sendt, Sjekk epost for bekreftelse.');
    }
  };
  return (
    <div>

      <nav className={`custom-navbar ${scrolled ? 'scrolled' : ''}`}>
        <div className="navbar-brand">
          <a href="/">
            <img className="navbar-logo" src={logo} alt="Logo" />
          </a>
        </div>
        <div className="navbar-center">
          <a
            className={`navbar-item ${activeSection === 'Home' ? 'active' : ''}`}
            onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
          >
            HJEM
          </a>
          <a
            className={`navbar-item ${activeSection === 'Our Cars' ? 'active' : ''}`}
            onClick={() => ourCarsRef.current.scrollIntoView({ behavior: 'smooth' })}
          >
            BILER
          </a>
          <a
            className={`navbar-item ${activeSection === 'About Us' ? 'active' : ''}`}
            onClick={() => aboutUsRef.current.scrollIntoView({ behavior: 'smooth' })}
          >
            OM OSS
          </a>
          <a
            className={`navbar-item ${activeSection === 'Contact' ? 'active' : ''}`}
            onClick={() => contactRef.current.scrollIntoView({ behavior: 'smooth' })}
          >
            KONTAKT
          </a>

        </div>
      </nav>



      {/* The Page Loader */}
      {isLoading && <div className="loader-container"> <div className="loader"></div> </div>}

      {/* The Sidebar */}
      <div className="sidebar" style={{ opacity: isSidebarVisible ? 1 : 0, transition: 'opacity 0.5s' }}>
        <a onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })} className={activeSection === 'Home' ? 'is-active' : ''}>
          <FontAwesomeIcon icon={faHome} />
          {activeSection === 'Home' && <span>HJEM</span>}
        </a>
        <a onClick={() => ourCarsRef.current.scrollIntoView({ behavior: 'smooth' })} className={activeSection === 'Our Cars' ? 'is-active' : ''}>
          <FontAwesomeIcon icon={faCar} />
          {activeSection === 'Our Cars' && <span>BILER</span>}
        </a>
        <a onClick={() => aboutUsRef.current.scrollIntoView({ behavior: 'smooth' })} className={activeSection === 'About Us' ? 'is-active' : ''}>
          <FontAwesomeIcon icon={faUser} />
          {activeSection === 'About Us' && <span>OM OSS</span>}
        </a>
        <a onClick={() => contactRef.current.scrollIntoView({ behavior: 'smooth' })} className={activeSection === 'Contact' ? 'is-active' : ''}>
          <FontAwesomeIcon icon={faPhone} />
          {activeSection === 'Contact' && <span>KONTAKT</span>}
        </a>
      </div>

      <section className="hero is-fullheight-with-navbar">
        <div className="hero-body">
          <div className="container has-text-centered">
            <h1 className="title is-1 has-text-weight-bold">Kvalitet Møter Service</h1>
            <h2 className="subtitle ">Velkommen til Hindklev Auto – hvor din neste bil venter. Utforsk vårt nøye utvalgte bilutvalg og opplev en handel der kvalitet og service er i fokus.</h2>
            <button className="button is-black is-rounded" onClick={() => ourCarsRef.current.scrollIntoView({ behavior: 'smooth' })}>
              Se Våre Biler
            </button>
          </div>
        </div>
        <div className="hero-foot">
          <div className="container has-text-centered">
            <img src={heroImage} alt="Car" />
          </div>
        </div>
      </section>






      {/* Cars Section */}
      <div ref={ourCarsRef}>
        <section className="section cars-section">
          <div className="container">
            <h1 className="title">Vårt Utvalg</h1>
            <div className="columns">
              <iframe src="https://www.finn.no/pw/search/car-norway?orgId=9474481"
                width="100%" height="700" frameborder="0" scrolling="auto" />
            </div>
          </div>
        </section>
      </div>


      <div ref={aboutUsRef} className="about-us-container">
        <section className="about-section">
          <div className="container">
            <div className="columns is-variable is-6">
              <div className="column">
                <h1 className="title about-title">Om Oss</h1>
                <p className="about-text">
                  Velkommen til Hindklev Auto, din pålitelige destinasjon for høykvalitets bruktbiler på Østlandet og Trøndelag.
                </p>
                <p className="about-text">
                  Hos Hindklev Auto brenner vi for å gi kundene våre førsteklasses kjøretøy og eksepsjonell service. Med flere års erfaring i bransjen har vi opparbeidet oss et rykte for pålitelighet, ærlighet og engasjement for kundetilfredshet.
                </p>
                <ul className="about-list">
                  <li className="about-list-item">
                    <FontAwesomeIcon icon={faCar} className="fa-icon" />
                    <strong>Premium brukte biler:</strong> Vi tilbyr et håndplukket utvalg av brukte kjøretøyer som har gjennomgått strenge inspeksjoner for å sikre at de oppfyller de høyeste kvalitetsstandardene. Enten du leter etter en stilig sedan, en allsidig SUV eller en pålitelig familiebil, har vi det perfekte kjøretøyet for deg.
                  </li>
                  <li className="about-list-item">
                    <FontAwesomeIcon icon={faShieldAlt} className="fa-icon" />
                    <strong>Utvidede garantier:</strong> Vi står bak kvaliteten på bilene våre, og det er grunnen til at vi gir opptil 36 måneders garanti på mange av våre kjøretøy. Kjør med selvtillit, vel vitende om at investeringen din er beskyttet.
                  </li>
                  <li className="about-list-item">
                    <FontAwesomeIcon icon={faExchangeAlt} className="fa-icon" />
                    <strong>Innbytte Velkommen:</strong> Ønsker du å oppgradere ditt nåværende kjøretøy? Vi gjør prosessen enkel ved å akseptere innbytte. Teamet vårt vil vurdere innbyttet ditt og tilby deg en konkurransedyktig pris for ditt neste kjøp.
                  </li>
                  <li className="about-list-item">
                    <FontAwesomeIcon icon={faTruck} className="fa-icon" />
                    <strong>Problemfrie leveranser:</strong> Vi forstår at bekvemmelighet er viktig. Det er derfor vi tilbyr problemfri levering av kjøretøy til ditt foretrukne sted, for å sikre at din bilkjøpsopplevelse er så smidig som mulig. Vi har også pick-up service fra Trondheim lufthavn værnes/Oslo gardemoen.
                  </li>
                  <li className="about-list-item">
                    <FontAwesomeIcon icon={faHandshake} className="fa-icon" />
                    <strong>Ekstra tjenester:</strong> Vår forpliktelse til å tilfredsstille deg slutter ikke med salget. Vi er her for å hjelpe med alt tillegget også, fra finansieringsalternativer til råd om vedlikehold av kjøretøy. Vårt dedikerte team er alltid klar til å hjelpe deg.
                  </li>
                </ul>
                <p className="about-text">
                  Takk for at du vurderer Hindklev Auto for ditt neste kjøp av bruktbil. Vi ser frem til å hjelpe deg med å finne det perfekte kjøretøyet som passer dine behov og overgår dine forventninger. Bli med i vår fornøyde kundebase og opplev forskjellen med Hindklev Auto.
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>



      <div ref={contactRef} className="contact-us-container">
        <section className="contact-section">
          <div className="container">
            <h2 className="form-title">Kontaktskjema</h2>
            <div className="contact-form-description">
              <p>Send oss en melding og vi vil komme tilbake til deg så snart som mulig.</p>
            </div>
            <form onSubmit={handleSubmit} className="contact-form">
              {/* ... rest of your form fields ... */}
              <div className="field">
                <label className="label">Velg emne</label>
                <div className="control">
                  <div className="select">
                    <select
                      value={selectedSubject}
                      onChange={(e) => setSelectedSubject(e.target.value)}
                    >
                      <option value="General Inquiry">Generell Hendvendelse</option>
                      <option value="Car Inquiry">Bilforespørsel</option>
                      <option value="Trade-in">Inbytte</option>
                      <option value="Warranty">Garanti</option>
                      <option value="Service">Service</option>
                      <option value="Other">Andre Hendvendelser</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="field">
                <label className="label">E-post</label>
                <div className="control">
                  <input
                    className="input"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Melding</label>
                <div className="control">
                  <textarea
                    className="textarea"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    required
                  ></textarea>
                </div>
              </div>
              <div className="field">
                <div className="control">
                  <button className="button is-link">Send</button>
                </div>
              </div>
            </form>
            <p>{status}</p>
          </div>
        </section>
      </div>


      {/* Footer */}
      <footer className="footer">
        <div className="container">
          <div className="columns">

            {/* Left Column: Logo */}
            <div className="column is-one-third has-text-left logo-container">
              <a onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
                <figure className="image is-128x128">
                  <img src={logopink} alt="Logo" width="112" height="28" />
                </figure>
              </a>
            </div>

            {/* Middle Column: Quicklinks */}
            <div className="column is-one-third has-text-centered">
              <ul>
                <li><a onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>Hjem</a></li>
                <li><a onClick={() => ourCarsRef.current.scrollIntoView({ behavior: 'smooth' })}>Biler</a></li>
                <li><a onClick={() => aboutUsRef.current.scrollIntoView({ behavior: 'smooth' })}>Om Oss</a></li>
                <li><a onClick={() => contactRef.current.scrollIntoView({ behavior: 'smooth' })}>Kontakt</a></li>
              </ul>
            </div>

            <div className="column is-one-third has-text-right">
              <a href="https://www.facebook.com/profile.php?id=100093612503977" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faFacebookF} className="fa-icon" />
              </a>
              <a href="https://www.instagram.com/hindklev_auto/" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faInstagram} className="fa-icon" />
              </a>
            </div>

          </div>

          {/* Copyright line */}
          <div className="has-text-centered footer-text">
            <a onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>Hindklev Gruppen</a>.
            &copy; {new Date().getFullYear()} All Rights Reserved.
          </div>

        </div>
      </footer>

    </div>



  );
}

export default App;
